import React from 'react';
import Fail from '../assets/images/fail.png';
import '../assets/styles/ErrorBanner.css';

export default function ErrorBanner({ message }: { message: string }) {
    return (
        <div className="error-banner">
            <img src={Fail} alt="fail" />
            <p>{message}</p>
        </div>
    )
}