import Quiz from "./Quiz";

export interface QuestionBankParseResult {
    isSuccessful: boolean;
    value: Quiz[] | string;
}

export class SuccessfulQuestionBankParseResult implements QuestionBankParseResult {
    public isSuccessful: boolean = true;
    public value: Quiz[];

    constructor(quizzes: Quiz[]) {
        this.value = quizzes
    }
}

export class FailedQuestionBankParseResult implements QuestionBankParseResult {
    public isSuccessful: boolean = false;
    public value: string;

    constructor(error: string) {
        this.value = error;
    }
}

