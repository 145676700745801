import React from "react";
import TabBar from "../components/TabBar";
import Tab from "../components/Tab";
import { Outlet, useParams, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Loader from "../components/Loader";
import { FadeAnimationLayout } from "../components/layouts/FadeAnimationLayout";
import useServer from "../hooks/useServer";
import { default as CourseSummary } from "../models/Course";
import { useSelector } from "react-redux";
import UpsertCourseModal from "../components/modals/UpsertCourseModal";
import CourseAction from "../models/CourseAction";
import Modal from "../components/modals/Modal";
import { toast } from "react-hot-toast";
import Logo from "../assets/images/logo-white.png";
import MenuBar from "../components/MenuBar";
import Background from "../assets/images/background.png";
import "../assets/styles/Course.css";

export default function Course(props: any) {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [course, setCourse] = React.useState<CourseSummary | undefined>();
    const [updateCourse, setUpdateCourse] = React.useState<boolean>(false);
    const [deleteCourse, setDeleteCourse] = React.useState<boolean>(false);

    const params = useParams();
    const location = useLocation();
    const courseId: number = Number.parseInt(params.courseId!);
    const isAdmin = useSelector((state: any) => state.user.isAdmin);

    const server = useServer();


    let tabs: {
        name: string,
        to: string,
        active?: boolean
    }[] = [
            {
                name: "Topics",
                to: "topics"
            },
            {
                name: "Practice Tests",
                to: "practice",
            },
            {
                name: "Exam Simulator",
                to: "exams"
            }
        ]

    // If user is on a specific tab, make that tab active
    tabs = tabs.map(tab => {
        if (location.pathname.includes(tab.to)) {
            return {
                ...tab,
                active: true
            }
        }
        return tab;
    })


    const totalCorrectQuestions = () => {
        let correct = 0;
        course?.quizzesSummary?.forEach((quizSummary) => {
            if (quizSummary.user_progress)
                correct += quizSummary.user_progress.correct;
        })

        return correct;
    }

    const totalIncorrectQuestions = () => {
        let incorrect = 0;
        course?.quizzesSummary?.forEach((quizSummary) => {
            if (quizSummary.user_progress)
                incorrect += quizSummary.user_progress.incorrect;
        })

        return incorrect;
    }

    const totalQuestions = () => {
        let total = 0;
        course?.quizzesSummary?.forEach((quizSummary) => {
            total += quizSummary.total_questions;
        })

        return total;
    }

    useEffect(() => {
        server.getCourse(courseId).then((course: CourseSummary) => {
            setCourse(course);
            setLoading(false);
        })
    }, []);

    return (
        <React.Fragment>
            {
                loading ?
                    <Loader />
                    :
                    course &&
                    <div className="course-page">
                        <React.Fragment>
                            <FadeAnimationLayout>
                                <MenuBar />

                                <div id="course-banner">
                                    <img id="logo" src={Logo} alt="logo" />
                                    <img id="background" src={Background} alt="background" />
                                    <div className="content-wrapper">
                                        <h1 id="title">{course.name}</h1>
                                        {
                                            isAdmin &&
                                            <div id="admin-controls">
                                                <div className="control" id="edit" onClick={() => setUpdateCourse(true)}>
                                                    <p>Edit</p>
                                                </div>
                                                <div className="control" id="delete" onClick={() => setDeleteCourse(true)}>
                                                    <p>Delete</p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div id="progress">
                                        <div id="answered">
                                            <div className="category" id="correct">
                                                <div className='circle correct' />
                                                <p>{totalCorrectQuestions()} correct</p>
                                            </div>
                                            <div className="category" id="incorrect">
                                                <div className='circle incorrect' />
                                                <p>{totalIncorrectQuestions()} incorrect</p>
                                            </div>
                                            <div className="category" id="total">
                                                <div className='circle total' />
                                                <p>{totalQuestions()} total questions</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </FadeAnimationLayout>
                            <div className="content-wrapper">

                                <TabBar>
                                    {
                                        tabs.map((tab, index) => {
                                            return (
                                                <Tab
                                                    key={index}
                                                    name={tab.name}
                                                    to={`/courses/${courseId}/${tab.to}`}
                                                    active={tab.active}
                                                />
                                            )
                                        }
                                        )
                                    }
                                </TabBar>
                                <Outlet context={{ course: course, setCourse: setCourse }} />
                            </div>
                        </React.Fragment>

                        {
                            isAdmin && updateCourse &&
                            <UpsertCourseModal
                                action={CourseAction.UPDATE}
                                courseId={courseId}
                                onClose={() => setUpdateCourse(false)} />
                        }
                        {
                            isAdmin && deleteCourse &&
                            <Modal
                                onCancel={() => setDeleteCourse(false)}
                                onConfirm={() => {
                                    server.adminDeleteCourse(courseId).then((response: any) => {
                                        setDeleteCourse(false);
                                        toast.success("Course deleted successfully! You will be redirected to the courses page.");
                                        setTimeout(() => {
                                            window.location.href = "/courses";
                                        }, 2000);
                                    })
                                }}
                                title="Are you sure you want to delete this course?"
                                description="This action cannot be undone!"
                                confirmLabel="Delete"
                                cancelLabel="Cancel"
                            />
                        }
                    </div>
            }
        </React.Fragment>
    )
}