export async function request(
    address: string,
    method: string = 'GET',
    body: any | undefined = undefined,
) {
    return fetch(address, {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(body),
    })
        .then(response => handleResponse(response))
        .catch(error => {
            console.error(error);
            return [];
        })
}

function handleResponse(response: any) {
    if (response.status === 200)
        return response.json();
    throw new Error('Not logged in');
}

export function extractValuesFromArray(content: string, arrayName: string) {
    const regex = `let\\s+${arrayName}\\s*=\\s*\\[([\\s\\S]*?)\\]`;
    const values = new RegExp(regex).exec(content)![1].split(/",\s+/).map(value => value.replace(/"/g, ''));

    return values;
}

export function getHash(object: any) {
    const hash = require('object-hash');
    return hash(object);
}