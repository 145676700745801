import { useSelector, useDispatch } from "react-redux";
import Course from "../models/Course";
import Quiz from "../models/Quiz";
import Question from "../models/Question";
import QuizSummary from "../models/QuizSummary";
import { toast } from "react-hot-toast";

export default function useServer() {
    const address = useSelector((state: any) => state.server.address);
    const dispatch = useDispatch();


    const request = async function (
        address: string,
        method: string = 'GET',
        body: any | undefined = undefined,
    ) {
        return fetch(address, {
            method,
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(body),
        })
            .then(response => {
                // Set server as online
                dispatch({ type: 'server/online/true' });

                // Handle response
                return handleResponse(response)
            })
            .catch(error => {
                console.log({ error });

                dispatch({ type: 'server/online/false' });
            })
    }

    const handleResponse = async (response: any) => {
        switch (response.status) {
            case 200:
                dispatch({ type: 'isAuthenticated/set' });
                dispatch({ type: 'isEnrolled/set' });

                return await response.json();
            case 401:
                response = await response.json();
                if (response.message === "Not authenticated!") {
                    dispatch({ type: 'isAuthenticated/unset' });
                    dispatch({ type: 'auth_url/set', payload: response.auth_url })
                    return;
                }
                else if (response.message === "Not enrolled!") {
                    dispatch({ type: 'isEnrolled/unset' });
                    dispatch({ type: 'enroll_url/set', payload: response.enroll_url })
                    return;
                }
                else {
                    toast.error(response.message);
                }
        }
    }

    return {
        generateSession: (oAuthCode: string) => {
            return request(
                address + '/session?code=' + oAuthCode,
            ).then((session: any) => session);
        },
        getMyInfo: () => {
            return request(
                address + '/me',
            ).then((me: any) => me);
        },
        getCourses: (): Promise<Course[]> => {
            return request(
                address + '/courses',
            ).then((response: any): Course[] => {
                if (!response) return [];
                return response.courses.map((course: any): Course => {
                    return {
                        id: course.id,
                        name: course.name,
                        description: course.description,
                    };
                })
            });
        },
        getCourse: (id: number): Promise<Course> => {
            return request(
                address + '/course?id=' + id,
            ).then((course: Course): Course => course);

        },
        getQuiz: (courseId: number, quizId: string): Promise<Quiz | undefined> => {
            return request(
                address + '/quiz?courseId=' + courseId + '&quizId=' + quizId,
            ).then((quiz: any): Quiz | undefined => {
                if (!quiz) return;
                return {
                    id: quiz.id,
                    type: quiz.type,
                    name: quiz.name,
                    description: quiz.description,
                    questions: quiz.questions
                };
            });
        },
        getQuestion: (courseId: number, quizId: string, questionId: number): Promise<Question> => {
            return request(
                address + '/question?courseId=' + courseId + '&quizId=' + quizId + '&questionId=' + questionId,
            ).then(question => question);
        },
        getDemoQuestion: (courseId: number, quizId: string): Promise<Question | undefined> => {
            return fetch(address + `/quiz/demo?courseId=${courseId}&quizId=${quizId}`, {
                headers: { 'Content-Type': 'application/json' },
            }).then(response => {
                if (response.status === 200) return response.json();
                return undefined;
            })
                .catch(error => undefined);
        },
        submitChoice(courseId: number, quizId: string, questionId: number, choiceId: number) {
            return request(
                address + '/question/answer?courseId=' + courseId + '&quizId=' + quizId + '&questionId=' + questionId + '&choiceId=' + choiceId,
                'POST',
            ).then((response: any) => response);
        },
        resetQuiz(courseId: number, quizId: string) {
            return request(
                address + '/quiz/reset?courseId=' + courseId + '&quizId=' + quizId,
                'POST',
            ).then((response: any) => response);
        },
        logout() {
            return request(
                address + '/me/logout',
                'POST',
            ).then((response: any) => response);
        },
        adminGetCourses(hasQuizzes?: boolean): Promise<Course[]> {
            return request(
                address + '/admin/courses' + (hasQuizzes !== undefined ? `?hasQuizzes=${hasQuizzes}` : ''),
            ).then((courses: any): Course[] => {
                if (!courses) return [];
                return courses.map((course: any): Course => {
                    return {
                        id: course.id,
                        name: course.name,
                        description: course.description,
                    };
                })
            });
        },
        adminUpsertCourse(courseId: number, quizzes: Quiz[]) {
            return request(
                address + '/admin/courses/upsert',
                'POST',
                {
                    courseId,
                    quizzes,
                }
            ).then((response: any) => response);
        },
        adminDeleteCourse(id: number) {
            return request(
                address + '/admin/courses/delete',
                'POST',
                {
                    id,
                }
            ).then((response: any) => response);
        }
    }
}