import '../../assets/styles/Button.css';

interface ButtonProps {
    onClick: () => void;
    label: string;
}


export default function Button({ onClick, label }: ButtonProps) {
    return (
        <div className="btn" onClick={onClick}>
            <p>{label}</p>
        </div>
    )
}