
const initialState: any = {
    isAuthenticated: true,
    isEnrolled: true,
    isAdmin: false,
    inExam: false,
};


export default function UserReducer(state = initialState, action: any) {
    switch (action.type) {
        case 'isAuthenticated/set':
            return {
                ...state,
                isAuthenticated: true,
            };

        case 'isAuthenticated/unset':
            return {
                ...state,
                isAuthenticated: false,
            };

        case 'isEnrolled/set':
            return {
                ...state,
                isEnrolled: true,
            };

        case 'isEnrolled/unset':
            return {
                ...state,
                isEnrolled: false,
            };

        case 'isAdmin/set':
            return {
                ...state,
                isAdmin: true,
            };

        case 'isAdmin/unset':
            return {
                ...state,
                isAdmin: false,
            };

        case 'inExam/set':
            return {
                ...state,
                inExam: true,
            };

        case 'inExam/unset':
            return {
                ...state,
                inExam: false,
            };



        default:
            return state;
    }
}