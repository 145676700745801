import React from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Loader from "../components/Loader";
import { useDispatch } from "react-redux";
import { FadeAnimationLayout } from "../components/layouts/FadeAnimationLayout";
import useServer from "../hooks/useServer";
import Back from "../assets/images/back.png";
import toast from "react-hot-toast";
import Quiz from "../models/Quiz";
import Question from "../models/Question";
import QuizType from "../models/QuizType";
import Modal from "../components/modals/Modal";
import BackButton from "../components/buttons/BackButton";
import QuizResultsModal from "../components/modals/QuizResultsModal";
import Timer from "../components/Timer";
import "../assets/styles/Classroom.css";



export default function Classroom() {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [questionLoading, setQuestionLoading] = React.useState<boolean>(false);
    const [pendingQuestionResponse, setPendingQuestionResponse] = React.useState<boolean>(false);

    const [finished, setFinished] = React.useState<boolean>(false);

    // Display the results modal
    const [displayResults, setDisplayResults] = React.useState<boolean>(false);

    // Display modal if the user wants to exit while in EXAM mode
    const [shouldExit, setShouldExit] = React.useState<boolean>(false);



    // The quiz containing the questions
    const [quiz, setQuiz] = React.useState<Quiz | undefined>();

    // The index of the current question
    const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState<number>(0);

    const params = useParams();
    const server = useServer();
    const dispatch = useDispatch();

    const courseId: number = Number.parseInt(params.courseId!);
    const quizId: string = params.quizId!;

    const currentQuestion: Question | undefined = quiz?.questions && quiz?.questions[currentQuestionIndex];

    useEffect(() => {
        server.getQuiz(courseId, quizId)
            .then((quiz: Quiz | undefined) => {
                if (!quiz) return;
                setQuiz(quiz);
                setLoading(false);

                // Activate anti-cheat
                setAntiCheat(true);

                // Start the timer if the quiz is an exam
                if (quiz?.type === QuizType.EXAM) {
                    // Activate exam mode
                    // Basically hide the menu header
                    dispatch({ type: "inExam/set" })

                }

                // If all questions are answered, set finished to true
                if (quiz.questions!.every((question: any) => question.submittedChoiceId)) {
                    setFinished(true);
                }
            })

        // Disable anti-cheat on unmount
        return () => {
            setAntiCheat(false);

            // Disable exam mode
            dispatch({ type: "inExam/unset" })
        }
    }, []);

    const setAntiCheat = (enable: boolean): void => {
        if (enable) {
            let displayError = (message: string): boolean => {
                toast.error(message)
                return false;
            }
            // Store the current handlers
            const contextmenuHandler = document.oncontextmenu;
            const selectstartHandler = document.onselectstart;
            const dragstartHandler = document.ondragstart;
            const keydownHandler = document.onkeydown;

            // Disable the current handlers
            document.oncontextmenu = () => displayError("Right click is disabled!");
            document.onselectstart = () => false;
            document.ondragstart = () => displayError("Dragging is disabled!");
            document.onkeydown = (e) => {
                if (e.key === "F12") {
                    displayError("Developer tools are disabled!");
                    e.preventDefault();
                }
            }
        }
        else {
            // Restore the current handlers
            document.oncontextmenu = null;
            document.onselectstart = null;
            document.ondragstart = null;
            document.onkeydown = null;
        }
    }


    const exitClassroom = () => {
        window.location.href = `/courses/${courseId}/topics`;
    }

    const submitChoice = (questionId: number, choiceId: number) => {
        if (pendingQuestionResponse) return;

        if (currentQuestion!.submittedChoiceId) return;
        currentQuestion!.submittedChoiceId = choiceId;
        setPendingQuestionResponse(true);

        server.submitChoice(courseId, quizId, questionId, choiceId)
            .then((response: any) => {
                console.log({ response })
                setQuiz((quiz: any) => {
                    const question = quiz.questions.find((question: any) => question.id === questionId);
                    question.submittedChoice = choiceId;
                    question.correctChoiceId = response.correctChoiceId;
                    question.explanation = response.explanation;
                    return quiz;
                })

                // If user has answered the last question
                // set finished to true
                if (currentQuestionIndex === quiz!.questions!.length - 1) {
                    setFinished(true);
                }

                // If user has answered all questions
                // display the results modal
                if (quiz!.questions!.every((question: any) => question.submittedChoiceId)) {
                    setDisplayResults(true);
                }


                setPendingQuestionResponse(false);
            })

    }

    const goToQuestion = (index: number) => {
        if (quiz?.type === QuizType.EXAM) {
            if (index > currentQuestionIndex) {
                // If question has not been answered
                if (!currentQuestion?.submittedChoiceId) {
                    toast.error("You can't go to the next question without answering during an exam!");
                    return;
                }
                if (index - currentQuestionIndex > 1) {
                    toast.error("You can't skip questions during an exam!");
                    return
                }
            }
        }

        if (index < 0 || index >= quiz!.questions!.length) return;

        // If question content is not fetched, we fetch it now
        if (!quiz!.questions![index].text) {
            setQuestionLoading(true);
            setCurrentQuestionIndex(index);
            server.getQuestion(courseId, quizId, quiz!.questions![index].id)
                .then((question: any) => {
                    setQuiz((quiz: any) => {
                        quiz.questions[index] = question;
                        return quiz;
                    });
                    setQuestionLoading(false);
                })
        }
        else {
            setCurrentQuestionIndex(index);
        }

        // Scroll to the top of the page
        window.scrollTo(0, 0);

    }

    const getChoiceClassName = (choiceId: number): string => {
        let className = "choice";

        if (currentQuestion?.submittedChoiceId === choiceId) {
            className += " selected";
        }

        if (pendingQuestionResponse) {
            if (currentQuestion?.submittedChoiceId === choiceId) {
                className += " pending";
            }
        }
        else
            if (currentQuestion?.submittedChoiceId) {
                // Mark the correct choice as correct
                if (currentQuestion.correctChoiceId === choiceId) {
                    className += " correct";
                }

                // Mark the submitted choice as incorrect if it is not the correct choice
                if (currentQuestion.submittedChoiceId === choiceId && currentQuestion.submittedChoiceId !== currentQuestion.correctChoiceId) {
                    className += " incorrect";
                }
            }

        return className;
    }

    const getQuestionClassName = (questionId: number): string => {
        let className = "question";

        const question = quiz?.questions!.find((question: any) => question.id === questionId);
        if (question?.submittedChoiceId && question?.correctChoiceId) {
            if (question.submittedChoiceId === question.correctChoiceId) {
                className += " correct";
            }
            else {
                className += " incorrect";
            }
        }

        if (questionId === currentQuestion!.id) {
            className += " current";
        }

        return className;
    }

    const getCorrectQuestionsCount = (): number => {
        let correct = 0;
        if (quiz) {
            for (let question of quiz.questions!) {
                if (question.submittedChoiceId && question.submittedChoiceId === question.correctChoiceId) {
                    correct++;
                }
            }
        }
        return correct;
    }

    const getIncorrectQuestionsCount = (): number => {
        let incorrect = 0;
        if (quiz) {
            for (let question of quiz.questions!) {
                if (question.submittedChoiceId)
                    if (question?.correctChoiceId && question.submittedChoiceId !== question.correctChoiceId) {
                        incorrect++;
                    }
            }
        }
        return incorrect;
    }

    const getUnansweredQuestionsCount = (): number => {
        let unanswered = 0;
        if (quiz) {
            for (let question of quiz.questions!) {
                if (!question.submittedChoiceId) {
                    unanswered++;
                }
            }
        }
        return unanswered;
    }

    const reviewQuiz = () => {
        setDisplayResults(false);
        setAntiCheat(false);
        goToQuestion(0);
    }

    const retakeQuiz = () => {
        server.resetQuiz(courseId, quizId)
            .then((response: any) => {
                // Refresh the page
                window.location.reload();
            })
    }

    return (
        <React.Fragment>
            {
                loading ?
                    <Loader />
                    :
                    quiz &&
                    <div className="classroom-page">
                        <React.Fragment>
                            <FadeAnimationLayout>
                                <div className="quiz">
                                    <div id="header">
                                        <BackButton
                                            onClick={() => {
                                                if (quiz.type === QuizType.EXAM) {
                                                    setShouldExit(true);
                                                }
                                                else {
                                                    exitClassroom();
                                                }
                                            }}
                                            image={Back}
                                            label="Exit"
                                        />
                                        <h1 id="title">{quiz.name}</h1>
                                        {
                                            quiz.type === QuizType.EXAM &&
                                            <Timer />
                                        }
                                        {
                                            finished &&
                                            <div className="submit" onClick={() => setDisplayResults(true)}>
                                                <p>Submit</p>
                                            </div>
                                        }
                                    </div>

                                    <div id="question-wrapper">
                                        {/* <FadeAnimationLayout> */}
                                        {
                                            questionLoading ? <Loader /> :
                                                <React.Fragment>
                                                    <div id="text">
                                                        {currentQuestion?.text.split("\n").map((paragraph: string) => {
                                                            return (
                                                                <p>{paragraph}</p>
                                                            )

                                                        })}
                                                    </div>
                                                    {
                                                        currentQuestion?.image &&
                                                        <div id="image">
                                                            <img src={`data:image/jpeg;base64,${currentQuestion.image}`} alt="question" />
                                                        </div>
                                                    }
                                                    <div id="choices">
                                                        {
                                                            currentQuestion?.choices.map((choice: any) => {
                                                                return (
                                                                    <div key={choice.id}
                                                                        className={getChoiceClassName(choice.id)}
                                                                        onClick={() => submitChoice(currentQuestion!.id, choice.id)}
                                                                    >
                                                                        <p>{choice.text}</p>
                                                                    </div>
                                                                )
                                                            }
                                                            )
                                                        }
                                                    </div>

                                                    {
                                                        currentQuestion?.submittedChoiceId && !pendingQuestionResponse &&

                                                        <div id="explanation">
                                                            {/* <FadeAnimationLayout> */}
                                                            <p id="banner">Explanation</p>
                                                            {
                                                                currentQuestion.explanation &&
                                                                currentQuestion.explanation.split("\n").map((paragraph: string) => {
                                                                    return (
                                                                        <p>{paragraph}</p>
                                                                    )
                                                                }
                                                                )
                                                            }
                                                            {/* </FadeAnimationLayout> */}
                                                        </div>

                                                    }
                                                </React.Fragment>
                                        }

                                        {/* </FadeAnimationLayout> */}
                                    </div>


                                    <div id="navigation">
                                        <button className={`${currentQuestionIndex === 0 ? 'disabled' : ''}`} id="previous" onClick={() => goToQuestion(currentQuestionIndex - 1)}>Previous</button>
                                        <button className={`${currentQuestionIndex === quiz!.questions!.length - 1 ? 'disabled' : ''}`} id="next" onClick={() => goToQuestion(currentQuestionIndex + 1)}>Next</button>
                                    </div>


                                    <div id="questions-progress">
                                        {
                                            quiz.questions!.map((question: any, index: number) => {
                                                return (
                                                    <div key={question.id} className={getQuestionClassName(question.id)}
                                                        onClick={() => goToQuestion(index)}>
                                                        <p>{index + 1}</p>
                                                    </div>
                                                )
                                            }
                                            )
                                        }
                                    </div>

                                    <div id="answers-status">
                                        <div id="correct">
                                            <div className="circle correct"></div>
                                            <p>{getCorrectQuestionsCount()} correct</p>
                                        </div>
                                        <div id="incorrect">
                                            <div className="circle incorrect"></div>
                                            <p>{getIncorrectQuestionsCount()} incorrect</p>
                                        </div>
                                        <div id="unanswered">
                                            <div className="circle unanswered"></div>
                                            <p>{getUnansweredQuestionsCount()} unanswered</p>
                                        </div>
                                    </div>
                                </div>
                                {
                                    displayResults &&
                                    <QuizResultsModal
                                        correct={getCorrectQuestionsCount()}
                                        incorrect={getIncorrectQuestionsCount()}
                                        total={quiz.questions!.length}
                                        title={quiz.name}
                                        onReview={reviewQuiz}
                                        onRetake={retakeQuiz}
                                        onExit={exitClassroom}
                                    />
                                }
                            </FadeAnimationLayout>
                            {
                                quiz.type === QuizType.EXAM && shouldExit &&
                                <div className="exit-modal">
                                    <Modal
                                        title="Are you sure you want to exit?"
                                        description="All your progress will be lost!"
                                        confirmLabel="Exit"
                                        cancelLabel="Cancel"
                                        onCancel={() => setShouldExit(false)}
                                        onConfirm={exitClassroom}
                                    />
                                </div>
                            }
                        </React.Fragment>
                    </div>
            }
        </React.Fragment>
    )
}
