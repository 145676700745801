
const initialState: any = {
    address: '/api',
    // address: 'http://127.0.0.1:3001/api',
    online: true,
};


export default function ServerReducer(state = initialState, action: any) {
    switch (action.type) {
        case 'server/online/false':
            return {
                ...state,
                online: false,
            };

        case 'server/online/true':
            return {
                ...state,
                online: true,
            };
        default:
            return state;
    }
}