import '../../assets/styles/UpsertCourse.css';
import Close from '../../assets/images/close.png';
import Success from '../../assets/images/success.png';
import Fail from '../../assets/images/fail.png';
import { FadeAnimationLayout } from '../layouts/FadeAnimationLayout';
import CourseAction from '../../models/CourseAction';
import Course from '../../models/Course';
import Question from '../../models/Question';
import Quiz from '../../models/Quiz';
import Image from '../../models/Image';
import useServer from '../../hooks/useServer';
import React, { useEffect } from 'react';
import Loader from '../Loader';
import toast from 'react-hot-toast';
import { QuestionBankParseResult } from '../../models/QuestionBankParseResult';
import QuestionBankFileParser from '../../models/QuestionBankFileParser';
import ImageArchiveParser from '../../models/ImageArchiveParser';
import { ImageArchiveParseResult } from '../../models/ImageArchiveParseResult';

export default function UpsertCourseModal(props: any) {
    const [loading, setLoading] = React.useState<boolean>(true);
    const action: CourseAction = props.action;

    const [teachableCourses, setTeachableCourses] = React.useState<any[]>([]);
    const [courseId, setCourseId] = React.useState<number | undefined>();
    const [quizzes, setQuizzes] = React.useState<Quiz[] | undefined>();


    const [courseNeedsImages, setCourseNeedsImages] = React.useState<boolean>(false);
    const [courseFileParseSuccessful, setCourseFileParseSuccessful] = React.useState<boolean | undefined>();
    const [imageFileParseSuccessful, setImageFileParseSuccessful] = React.useState<boolean | undefined>();

    const [canSubmit, setCanSubmit] = React.useState<boolean>(false);

    const [withImages, setWithImages] = React.useState<boolean>(true);

    const server = useServer();

    useEffect(() => {
        if (action === CourseAction.ADD) {
            // Get teachable courses that have no quizzes
            server.adminGetCourses(false).then((courses: Course[]) => {
                setTeachableCourses(courses);
                setLoading(false);
            });
        }
        else if (action === CourseAction.UPDATE) {
            // Set the courseId from props
            setCourseId(props.courseId);
            setLoading(false);
        }
    }, []);

    const selectCourseId = (event: any) => {
        setCourseId(Number.parseInt(event.target.value));
    }

    const checkSubmittable = () => {

        try {
            if (!canSubmit) {
                if (!courseId) throw new Error("No course selected.");
                if (!quizzes) throw new Error("No quizzes found.");
                if (!courseFileParseSuccessful) throw new Error("Course file not parsed successfully.");
                if (courseNeedsImages && !withImages) throw new Error("Course needs images.");
                if (withImages && !imageFileParseSuccessful) throw new Error("Image file not parsed successfully.");
                console.log({ courseId, quizzes, courseFileParseSuccessful, imageFileParseSuccessful, withImages })
                setCanSubmit(true);
            }
            else {
                if (!courseId) throw new Error("No course selected.");
                if (!quizzes) throw new Error("No quizzes found.");
                if (!courseFileParseSuccessful) throw new Error("Course file not parsed successfully.");
                if (withImages && !imageFileParseSuccessful) throw new Error("Image file not parsed successfully.");
                console.log({ courseId, quizzes, courseFileParseSuccessful, imageFileParseSuccessful, withImages })
            }
        }
        catch (error) {
            if (canSubmit)
                setCanSubmit(false);
        }
    }

    const handleCourseFile = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const contents = e.target.result;
                const result: QuestionBankParseResult = QuestionBankFileParser.parse(contents);

                // Check the result
                if (result.isSuccessful) {
                    const quizzes: Quiz[] = result.value as Quiz[];
                    setQuizzes(quizzes);
                    setCourseFileParseSuccessful(true);

                    // If course needs images
                    if (quizzes.find((quiz: Quiz) => quiz.questions!.find((question: Question) => question.image !== undefined))) {
                        setCourseNeedsImages(true);
                    }
                }
                else {
                    const errorMessage: string = result.value as string;
                    toast.error(errorMessage, { duration: 5000 });
                    setCourseFileParseSuccessful(false);
                }
            };
            reader.readAsText(file);
        }
    }

    const handleImagesFile = async (event: any) => {
        const files = event.target.files;

        if (files) {
            const imageFile: File = files[0];
            if (!imageFile) return;

            // Parse the image archive
            const result: ImageArchiveParseResult = await ImageArchiveParser.parse(imageFile);
            if (!result.isSuccessful) {
                const errorMessage: string = result.value as string;
                toast.error(errorMessage, { duration: 5000 });
                setImageFileParseSuccessful(false);
                return;
            }

            // Extract the images
            const images: Image[] = result.value as Image[];


            let quizzesCopy: Quiz[] = JSON.parse(JSON.stringify(quizzes));

            // Check if there are all the images for the questions that require an image
            const questions: Question[] = quizzesCopy!.map((quiz: Quiz) => quiz.questions!).flat().filter((question: Question) => question.image !== undefined).flat();
            quizzesCopy?.forEach((quiz: Quiz) => {
                quiz!.questions!.forEach((question: Question) => {
                    if (question.image) {
                        questions.push(question);
                    }
                })
            })

            // Check if all the questions have an image
            for (const question of questions) {
                if (!images.find((image: { name: string, data: string }) => image.name === question.image)) {
                    const missingImageName = question.image;
                    toast.error(`The images in archive do not include the image '${missingImageName}' required by the questions. Please try again.`, { duration: 3000 });
                    setImageFileParseSuccessful(false);
                    return;
                }
            }
            // Attach images to questions
            questions.forEach((question: Question) => {
                const image = images.find((image: { name: string, data: string }) => image.name === question.image);
                if (image) {
                    question.image = image.data;
                }
            })

            // Update the quizzes
            setQuizzes(quizzesCopy);

            // If all the images are named correctly, set the images state
            setImageFileParseSuccessful(true);
        }
    }

    const upsertCourse = (action: CourseAction) => {
        setLoading(true);
        if (courseId && quizzes && courseFileParseSuccessful) {
            server.adminUpsertCourse(courseId, quizzes).then((response: any) => {
                if (action === CourseAction.ADD) {
                    props.onClose();
                    toast.success(`Course added successfully!`, { duration: 3000 });
                }

                window.location.reload();

            });
        }
    }

    checkSubmittable();

    return (
        <div className="upsert-course-popup">
            <FadeAnimationLayout>
                {
                    loading ?
                        <Loader /> :
                        <FadeAnimationLayout>
                            <div className="content-wrapper">
                                <div className='upsert-course-form'>
                                    <div id="close-btn" onClick={props.onClose}>
                                        <img src={Close} alt="Close" />
                                    </div>
                                    <h1 id="title">{
                                        action === CourseAction.ADD ?
                                            "Add a new course" :
                                            action === CourseAction.UPDATE ?
                                                "Edit course" :
                                                "Action"
                                    }</h1>
                                    {
                                        action === CourseAction.ADD &&
                                        <div className="step">
                                            <div id="course-select">
                                                <p id="step-title">Select the corresponding Teachable Course</p>
                                                {
                                                    teachableCourses.length === 0 ?
                                                        <div>
                                                            <p className='error-msg'>No teachable courses found.</p>
                                                            <p className='error-msg'>Please create a teachable course first.</p>
                                                        </div> :
                                                        <select id="course-dropdown" onChange={selectCourseId}>
                                                            <option value={undefined}>Select a course</option>
                                                            {
                                                                teachableCourses.map((course: any) => {
                                                                    return (
                                                                        <option
                                                                            key={course.id}
                                                                            value={course.id}
                                                                        >
                                                                            {course.id} - {course.name}
                                                                        </option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                }
                                            </div>
                                        </div>

                                    }
                                    {
                                        (action === CourseAction.ADD || action === CourseAction.UPDATE) &&
                                        <div className="step">
                                            <p id="step-title">Upload the course file</p>
                                            <div id="course-file">
                                                <input type="file" onChange={handleCourseFile} />
                                                <div id="status">

                                                    {
                                                        courseFileParseSuccessful !== undefined ?
                                                            courseFileParseSuccessful ?
                                                                <img src={Success} alt="success" /> :
                                                                <img src={Fail} alt="fail" />
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        (action === CourseAction.ADD || action === CourseAction.UPDATE) &&
                                        <div className='step' >
                                            <p id="step-title">Images</p>
                                            <fieldset id="course-image-file" disabled={quizzes !== undefined ? false : true}>
                                                <legend>Select an option:</legend>
                                                <div className='option' >
                                                    <input type="radio" name="images" onClick={() => setWithImages(false)} disabled={courseNeedsImages ? true : false} />
                                                    <label htmlFor="no-images">No images</label>
                                                </div>
                                                <div>
                                                    <div className='option'>
                                                        <input type="radio" defaultChecked={true} name="images" onClick={() => setWithImages(true)} />
                                                        <label htmlFor="no-images">With images</label>
                                                    </div>
                                                    <div id="image-file">
                                                        <input type="file"
                                                            disabled={!withImages}
                                                            onChange={handleImagesFile}
                                                        />
                                                        <div id="status">
                                                            {
                                                                imageFileParseSuccessful !== undefined ?
                                                                    imageFileParseSuccessful ?
                                                                        <img src={Success} alt="success" /> :
                                                                        <img src={Fail} alt="fail" />
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>


                                            </fieldset>

                                        </div>

                                    }

                                    <div
                                        id="submit-btn"
                                        className={canSubmit ? 'enabled' : 'disabled'}
                                        onClick={canSubmit ? () => upsertCourse(action) : () => { }}
                                    >
                                        <p>
                                            {
                                                action === CourseAction.ADD ?
                                                    "ADD" :
                                                    action === CourseAction.UPDATE ?
                                                        "UPDATE" :
                                                        "ACTION"
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </FadeAnimationLayout>

                }
            </FadeAnimationLayout>
        </div >
    )
}