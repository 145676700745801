import { FailedQuestionBankParseResult, QuestionBankParseResult, SuccessfulQuestionBankParseResult } from "./QuestionBankParseResult";
import { extractValuesFromArray, getHash } from "../Utils";
import QuizType from "./QuizType";
import Quiz from "./Quiz";
import Question from "./Question";

export default abstract class QuestionBankFileParser {
    private static demoQuestionRegex: RegExp = /t(\d+)-demo\.append\(Question\(([\s\S]*?)\)\)/g;
    private static questionRegex: RegExp = /t(\d+)\.append\(Question\(([\s\S]*?)\)\)/g;
    private static questionsContentRegex: RegExp = /longAns:\s*"+\s*([\s\S]*?)"+,\s*questionText:\s*"+\s*([\s\S]*?)"+,\s*choiceA:\s*"([\s\S]*?)",\s*choiceB:\s*"([\s\S]*?)",\s*choiceC:\s*"([\s\S]*?)",\s*choiceD:\s*"([\s\S]*?)",\s*answer:\s*(\d+),\s*imagineP:\s*"([\s\S]*?)"/g;

    private static topicsArrayIdentifier: string = 'arrayTesteTopics';
    private static practiceArrayIdentifier: string = 'arrayTestePractice';
    private static examsArrayIdentifier: string = 'arrayTesteExam';


    private static getQuizzesNb(content: string): number {
        // Extract all the questions with their content
        const questionsContent: RegExpMatchArray[] = [...content.matchAll(this.questionRegex)];

        // Sort questions by quiz id
        questionsContent.sort((a, b) => Number.parseInt(a[1]) - Number.parseInt(b[1]));

        // Return the number of quizzes
        return questionsContent.map(content => Number.parseInt(content[1])).reduce((a, b) => Math.max(a, b), 0);
    }

    private static parseQuestions(content: string, questionRegex: RegExp): { question: Question, quizIndex: number }[] {
        // Initialize the array of questions
        let toReturn: { question: Question, quizIndex: number }[] = [];

        // Extract all the questions with their content
        const questionsContent: RegExpMatchArray[] = [...content.matchAll(questionRegex)];

        // Sort questions by quiz id
        questionsContent.sort((a, b) => Number.parseInt(a[1]) - Number.parseInt(b[1]));

        // Get the number of quizzes
        const quizzesNb: number = questionsContent.map(content => Number.parseInt(content[1])).reduce((a, b) => Math.max(a, b), 0);

        for (const content of questionsContent) {
            // Get the quizIndex
            const quizIndex = Number.parseInt(content[1]);

            // If we have more questions than quizzes, stop
            if (quizIndex > quizzesNb) break;

            let matches = [...content[2].matchAll(this.questionsContentRegex)];
            const questionValues = matches[0]

            let question: any = {};

            question.text = questionValues[2];
            if (questionValues[8] !== "") question.image = questionValues[8];
            question.explanation = questionValues[1].indexOf("Explanation:") === -1 ? questionValues[1] : questionValues[1].split(/Explanation:\s*/)[1];


            let choices = [];
            const choicesIndexes = [3, 4, 5, 6];
            for (const choiceIndex of choicesIndexes) {
                if (questionValues[choiceIndex] === "") break;

                choices.push({
                    id: choicesIndexes.indexOf(choiceIndex) + 1,
                    text: questionValues[choiceIndex],
                })
            }

            question.choices = choices;
            question.correctChoiceId = Number.parseInt(questionValues[7]);

            toReturn.push({
                question,
                quizIndex: quizIndex - 1,
            });
        }

        return toReturn;
    }

    public static parse(content: string): QuestionBankParseResult {
        // Array of maps quiz type and quiz name
        let quizMappings: { type: QuizType, name: string }[] = [];

        // Extract the topics names and add them to the mappings
        const topics: string[] = extractValuesFromArray(content, this.topicsArrayIdentifier);
        if (topics.length === 0) return new FailedQuestionBankParseResult("No topics found!");
        else topics.forEach(quizName => quizMappings.push({ type: QuizType.TOPIC, name: quizName }));

        // Extract the practice tests names and add them to the mappings
        const practice: string[] = extractValuesFromArray(content, this.practiceArrayIdentifier);
        if (practice.length === 0) return new FailedQuestionBankParseResult("No practice quizzes found!");
        else practice.forEach(quizName => quizMappings.push({ type: QuizType.PRACTICE, name: quizName }));

        // Extract the exams names and add them to the mappings
        const exams: string[] = extractValuesFromArray(content, this.examsArrayIdentifier);
        if (exams.length === 0) return new FailedQuestionBankParseResult("No exams found!");
        else exams.forEach(quizName => quizMappings.push({ type: QuizType.EXAM, name: quizName }));

        // Get the quizzes number
        const quizzesNb: number = this.getQuizzesNb(content);
        if (quizzesNb !== quizMappings.length)
            return new FailedQuestionBankParseResult("The number of quizzes does not match the number of quizzes found in the arrays provided!");

        // Parse the questions
        const questionsWithQuizIndex: { question: Question, quizIndex: number }[] = this.parseQuestions(content, this.questionRegex);
        if (questionsWithQuizIndex.length === 0) return new FailedQuestionBankParseResult("No questions found!");

        // Parse the demo questions
        const demoQuestionsWithQuizIndex: { question: Question, quizIndex: number }[] = this.parseQuestions(content, this.demoQuestionRegex);
        if (demoQuestionsWithQuizIndex.length === 0) return new FailedQuestionBankParseResult("No demo questions found!");
        else {
            if (demoQuestionsWithQuizIndex.length !== topics.length)
                return new FailedQuestionBankParseResult("The number of demo questions does not match the number of topics!")
        }

        // Build the quizzes
        let quizzes: Quiz[] = [];

        quizMappings.forEach(quizMapping => {
            const quizIndex = quizMappings.indexOf(quizMapping);
            const quizQuestions = questionsWithQuizIndex.filter(item => item.quizIndex === quizIndex).map((item, itemIndex) => {
                // delete question.quizId;

                return {
                    ...item.question,
                    id: itemIndex,
                };
            });

            // If quiz is a topic, add the demo question
            if (quizMapping.type === QuizType.TOPIC) {
                const q: { question: Question, quizIndex: number }[] = demoQuestionsWithQuizIndex.filter(item => item.quizIndex === quizIndex);
                if (q.length === 0) return new FailedQuestionBankParseResult("No demo question found for topic " + quizMapping.name);

                quizzes.push({
                    // Id is the hash of the hash of the questions
                    id: getHash(quizQuestions),
                    name: quizMapping.name,
                    description: quizMapping.name,
                    type: quizMapping.type,
                    questions: quizQuestions,
                    demoQuestion: {
                        ...q[0].question,
                        id: 0,
                    },
                })
            }
            else {
                quizzes.push({
                    // Id is the hash of the hash of the questions
                    id: getHash(quizQuestions),
                    name: quizMapping.name,
                    description: quizMapping.name,
                    type: quizMapping.type,
                    questions: quizQuestions,
                })
            }

        })
        // Return the parsed quizzes
        return new SuccessfulQuestionBankParseResult(quizzes);
    }
}