import React from 'react';
import '../assets/styles/Timer.css';
import { useLayoutEffect } from 'react';


export default function Timer() {
    // Keep track of the time spent
    const [timeSpent, setTimeSpent] = React.useState<number>(0);

    const startTimer = () => {
        const interval = setInterval(() => {
            setTimeSpent((timeSpent: number) => timeSpent + 1);
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }

    const formatTime = (time: number): string => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time - hours * 3600) / 60);
        const seconds = time - hours * 3600 - minutes * 60;

        let toReturn = "";

        if (hours > 0) {
            toReturn += `${hours}:`;
        }

        toReturn += `${minutes < 10 ? 0 : ''}${minutes}:`;
        toReturn += `${seconds < 10 ? 0 : ''}${seconds}`;



        return toReturn;
    }

    useLayoutEffect(() => {
        // Start the timer
        startTimer();
    }, []);

    return (
        <div className="timer">
            <p>{formatTime(timeSpent)}</p>
        </div>
    )
}
