import { useSelector } from 'react-redux';
import QuizType from '../../models/QuizType';
import QuizSummary from '../../models/QuizSummary';
import React from 'react';
import Button from '../buttons/Button';
import { toast } from 'react-hot-toast';
import '../../assets/styles/QuizCard.css';

export default function QuizCard(props: any) {

    const isAdmin = useSelector((state: any) => state.user.isAdmin);

    const quizSummary: QuizSummary = props.quizSummary;
    let quizCardClassName = 'quiz-card';
    switch (quizSummary.type) {
        case QuizType.PRACTICE:
            quizCardClassName += ' practice';
            break;
        case QuizType.TOPIC:
            quizCardClassName += ' topic';
            break;
        case QuizType.EXAM:
            quizCardClassName += ' exam';
            break;
    }

    // If quiz has progress, add progress class
    if (quizSummary.user_progress)
        quizCardClassName += ' progress';

    const isQuizFinished = (): boolean => {
        if (!quizSummary.user_progress)
            return false;

        return quizSummary.user_progress && quizSummary.user_progress.correct + quizSummary.user_progress.incorrect === quizSummary.total_questions;
    }

    const onAdminCopyQuizDemoLink = () => {
        // Check if we have a secure context
        if (window.isSecureContext) {
            // Copy the link
            navigator.clipboard.writeText(`${window.location.origin}/demo/${props.courseId}/${quizSummary.id}`);

            // Show toast
            toast.success("Link copied to clipboard!");
        }
        else {
            toast.error("Cannot copy link to clipboard. Try opening the website in a secure context (HTTPS).");
        }


    }


    return (
        <div className={quizCardClassName}>
            <div id="details">
                <h1 id='name'>{quizSummary.name}</h1>
                <div id="progress">
                    <div id="answered">
                        {
                            quizSummary.user_progress &&
                            <React.Fragment>
                                <div className="category" id="correct">
                                    <div className='circle correct' />
                                    <p>{quizSummary.user_progress.correct} correct</p>
                                </div>
                                <div className="category" id="incorrect">
                                    <div className='circle incorrect' />
                                    <p>{quizSummary.user_progress.incorrect} incorrect</p>
                                </div>
                            </React.Fragment>
                        }
                        <div className="category" id="total">
                            <div className='circle total' />
                            <p>{quizSummary.total_questions} total questions</p>
                        </div>
                    </div>
                    {
                        quizSummary.user_progress &&
                        (quizSummary.user_progress.correct + quizSummary.user_progress.incorrect < quizSummary.total_questions) &&
                        <div className="category" id="warning">
                            <p>{quizSummary.total_questions - (quizSummary.user_progress.correct + quizSummary.user_progress.incorrect)} questions remaining</p>
                        </div>
                    }
                </div>
            </div>
            <div id="controls">
                {
                    quizSummary.user_progress &&
                    <Button label='Reset' onClick={props.onReset} />
                }
                {
                    isQuizFinished() && <Button label='Review' onClick={props.onReview} />
                }
                {
                    isQuizFinished() && <Button label='Exit' onClick={props.onExit} />
                }

                {
                    !isQuizFinished() && !quizSummary.user_progress &&
                    <Button label='Start' onClick={props.onStart} />
                }
                {
                    !isQuizFinished() && quizSummary.user_progress &&
                    <Button label='Continue' onClick={props.onContinue} />
                }
                {
                    isAdmin && <Button label='Copy link' onClick={onAdminCopyQuizDemoLink} />
                }
            </div>




        </div >
    )
}