import Back from '../../assets/images/back.png';
import '../../assets/styles/BackButton.css';

export default function BackButton(props: any) {
    return (
        <div id="back-btn"
            onClick={props.onClick}
        >
            <img src={Back} alt="back" />
            <p>Back</p>
        </div>
    )
}