import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Courses from './pages/Courses';
import Course from './pages/Course';
import Enroll from './pages/Enroll';
import Authenticate from './pages/Authenticate';
import { connect } from 'react-redux';
import Classroom from './pages/Classroom';
import { useEffect } from 'react';
import useServer from './hooks/useServer';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import QuizzesTab from './components/QuizzesTab';
import QuizType from './models/QuizType';
import Demo from './pages/Demo';

import './App.css';
import Loader from './components/Loader';
import Offline from './pages/Offline';


const mapStateToProps = (state: any) => {
  return {
    isAuthenticated: state.user.isAuthenticated,
    isEnrolled: state.user.isEnrolled,
    inExam: state.user.inExam,
    serverOnline: state.server.online,
  }
};



export default connect(mapStateToProps)(function App(props: any) {
  const [loading, setLoading] = React.useState<boolean>(true);
  const server = useServer();

  useEffect(() => {
    // Check if the user is authenticating
    const url = new URL(window.location.href);
    const code = url.searchParams.get("code");
    if (code) {
      server.generateSession(code)
        .then((response: any) => {
          if (response?.role) {
            if (response?.role === "owner") {
              props.dispatch({ type: "isAdmin/set" });
              localStorage.setItem("isAdmin", "true");
            }
            else {
              localStorage.setItem("isAdmin", "false");
            }
          }

          // Redirect to index
          window.location.href = "/";
        })

      return;
    }

    // Check if user is admin and set isAdmin state
    const isAdmin: string | null = localStorage.getItem("isAdmin");
    if (isAdmin != null) {
      if (isAdmin === "true") {
        props.dispatch({ type: "isAdmin/set" });
      }
      else if (isAdmin === "false") {
        props.dispatch({ type: "isAdmin/unset" });
      }
      setLoading(false);
    }
    // If no isAdmin state is set, check with the server
    else {
      server.getMyInfo().then((response: any) => {
        if (response?.role === "owner") {
          props.dispatch({ type: "isAdmin/set" });
          localStorage.setItem("isAdmin", "true");
        }
        else {
          localStorage.setItem("isAdmin", "false");
        }
        setLoading(false);
      });
    }


  }, []);

  return (
    <div className="App">
      {
        loading ?
          <Loader /> :
          (props.serverOnline === false) ?
            <Offline /> :
            <Router >
              <Toaster
                containerStyle={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "0 20px",
                }} />
              <Routes>
                <Route path="/demo/:courseId/:quizId" element={<Demo />} />
                {
                  (props.isEnrolled && props.isAuthenticated) ?
                    <>
                      <Route path="*" element={<Courses />} />
                      <Route path="classroom/:courseId/:quizId" element={<Classroom />} />
                      <Route path="courses" element={<Courses />} />
                      <Route path="courses/:courseId" element={<Course />} >
                        <Route path="practice" element={<QuizzesTab quizType={QuizType.PRACTICE} />} />
                        <Route path="topics" element={<QuizzesTab quizType={QuizType.TOPIC} />} />
                        <Route path="exams" element={<QuizzesTab quizType={QuizType.EXAM} />} />
                      </Route>
                    </>
                    :
                    <>
                      {
                        (!props.isAuthenticated) ?
                          <>
                            <Route path="*" element={<Authenticate />} />
                            <Route path="auth" element={<Authenticate />} />
                          </>
                          :
                          <>
                            <Route path="*" element={<Enroll />} />
                            <Route path="enroll" element={<Enroll />} />
                          </>
                      }
                    </>
                }
              </Routes>
            </Router>


      }
    </div>
  );
}
);
