import '../../assets/styles/Modal.css';
import { FadeAnimationLayout } from './../layouts/FadeAnimationLayout';


export default function Modal(props: any) {
    return (
        <div className="modal">
            <FadeAnimationLayout>

                <div className="content-wrapper">
                    <div className='modal-content'>
                        <h1>{props.title}</h1>
                        <p id="disclaimer">{props.description}</p>
                        <div className="buttons-wrapper">
                            <div className="button" id="confirm" onClick={props.onConfirm}>
                                <p>{props.confirmLabel}</p>
                            </div>
                            <div className="button" id="cancel" onClick={props.onCancel}>
                                <p>{props.cancelLabel}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </FadeAnimationLayout>

        </div>
    )
}