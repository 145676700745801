import { FadeAnimationLayout } from "../components/layouts/FadeAnimationLayout"
import Logo from "../assets/images/logo-purple.png";
import "../assets/styles/Offline.css";

export default function Offline() {

    return (
        <FadeAnimationLayout>
            <div className="offline-page">
                {/* <div className="logo">
                    <img src={Logo} alt="logo"/>
                </div> */}
                <h1 id="title">Server currently offline!</h1>
                <p id="description">
                    The server is currently offline. Please try again later.
                </p>
            </div>
        </FadeAnimationLayout>
    )
}