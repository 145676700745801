import '../../assets/styles/QuizResultsModal.css'
import { FadeAnimationLayout } from '../layouts/FadeAnimationLayout'


export default function QuizResultsModal(props: any) {
    return (
        <div className="results-modal">
            <FadeAnimationLayout>

                <div className="content-wrapper">
                    <div className="content">
                        <h1 id="title">{props.title}</h1>
                        <div id="results">
                            <p>Here are your results:</p>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="correct" id="key">Correct:</td>
                                        <td className="correct" id="value">{props.correct}</td>
                                    </tr>
                                    <tr>
                                        <td className="incorrect" id="key">Incorrect:</td>
                                        <td className="incorrect" id="value">{props.incorrect}</td>
                                    </tr>
                                    <tr>
                                        <td className="total" id="key">Total questions:</td>
                                        <td className="total" id="value">{props.total}</td>
                                    </tr>
                                    {
                                        props.timeTaken &&
                                        <tr>
                                            <td id="key">Time taken</td>
                                            <td id="value">{props.timeTaken}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div id="actions">
                            <div className="action" id="retake" onClick={props.onRetake}>
                                <p>Retake</p>
                            </div>
                            <div className="action" id="review" onClick={props.onReview}>
                                <p>Review</p>
                            </div>
                            <div className="action" id="exit" onClick={props.onExit}>
                                <p>Exit</p>
                            </div>
                        </div>
                    </div>
                </div>
            </FadeAnimationLayout>
        </div>
    )
}