import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Question from '../models/Question';
import useServer from '../hooks/useServer';
import Loader from '../components/Loader';
import ErrorBanner from '../components/ErrorBanner';
import { FadeAnimationLayout } from '../components/layouts/FadeAnimationLayout';
import '../assets/styles/Demo.css';

export default function Demo() {
    const [question, setQuestion] = React.useState<Question | null>(null);

    const [error, setError] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);

    const params = useParams();
    const server = useServer();

    const submitChoice = (choiceId: number) => {

        if (question?.submittedChoiceId) return;
        setQuestion({
            ...question!,
            submittedChoiceId: choiceId
        })

    }

    const getChoiceClassName = (choiceId: number): string => {
        let className = "choice";

        if (question?.submittedChoiceId === choiceId) {
            className += " selected";
        }

        if (question?.submittedChoiceId) {
            // Mark the correct choice as correct
            if (question.correctChoiceId === choiceId) {
                className += " correct";
            }

            // Mark the submitted choice as incorrect if it is not the correct choice
            if (question.submittedChoiceId === choiceId && question.submittedChoiceId !== question.correctChoiceId) {
                className += " incorrect";
            }
        }

        return className;
    }

    useEffect(() => {
        // Extract courseId and quizId from params
        const courseId: string | undefined = params.courseId;
        const quizId: string | undefined = params.quizId;

        // If courseId or quizId is not present, set error to true
        if (!courseId || !quizId) {
            setError(true);
            setLoading(false);
            return;
        }
        else {
            // Fetch the question from the server
            server.getDemoQuestion(Number.parseInt(courseId), quizId)
                .then((question: Question | undefined) => {
                    if (!question) {
                        setError(true);
                        // setQuestion({
                        //     id: 1,
                        //     quizId: 123,
                        //     text: "This is a demo question",
                        //     image: "",
                        //     choices: [
                        //         {
                        //             id: 1,
                        //             text: "Choice 1"
                        //         },
                        //         {
                        //             id: 2,
                        //             text: "Choice 2"
                        //         },
                        //         {
                        //             id: 3,
                        //             text: "Choice 3"
                        //         },
                        //         {
                        //             id: 4,
                        //             text: "Choice 4"
                        //         }
                        //     ],
                        //     correctChoiceId: 1,
                        //     explanation: "This is a demo explanation",
                        // });
                    }
                    else {
                        setQuestion(question);

                    }
                    setLoading(false);
                })
        }

    }, []);


    return (
        <div className='demo'>
            {
                loading ?
                    <Loader />
                    :
                    error ?
                        <ErrorBanner message='An error occured while loading the question!' />
                        :
                        <div className="quiz">
                            <div id="question-wrapper">
                                {/* <FadeAnimationLayout> */}
                                    {
                                        <React.Fragment>
                                            <div id="text">
                                                {question?.text.split("\n").map((paragraph: string) => {
                                                    return (
                                                        <p>{paragraph}</p>
                                                    )

                                                })}
                                            </div>
                                            {
                                                question?.image &&
                                                <div id="image">
                                                    <img src={`data:image/jpeg;base64,${question.image}`} alt="question" />
                                                </div>
                                            }
                                            <div id="choices">
                                                {
                                                    question?.choices.map((choice: any) => {
                                                        return (
                                                            <div key={choice.id}
                                                                className={getChoiceClassName(choice.id)}
                                                                onClick={() => submitChoice(choice.id)}
                                                            >
                                                                <p>{choice.text}</p>
                                                            </div>
                                                        )
                                                    }
                                                    )
                                                }
                                            </div>

                                            {
                                                question?.submittedChoiceId &&

                                                <div id="explanation">
                                                    {/* <FadeAnimationLayout> */}
                                                        <p id="banner">Explanation</p>
                                                        {
                                                            question.explanation &&
                                                            question.explanation.split("\n").map((paragraph: string) => {
                                                                return (
                                                                    <p>{paragraph}</p>
                                                                )
                                                            }
                                                            )
                                                        }
                                                    {/* </FadeAnimationLayout> */}
                                                </div>

                                            }
                                        </React.Fragment>
                                    }

                                {/* </FadeAnimationLayout> */}
                            </div>
                        </div>
            }
        </div>
    );
}