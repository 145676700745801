
import { combineReducers } from "redux";
import SchoolReducer from "./School";
import UserReducer from "./User";
import ServerReducer from "./Server";

const rootReducer = combineReducers({
    user: UserReducer,
    school: SchoolReducer,
    server: ServerReducer,
});

export default rootReducer;