import React from "react";
import Logo from '../assets/images/logo-purple.png';
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Loader from "../components/Loader";
import CourseCard from "../components/cards/CourseCard";
import "../assets/styles/Courses.css";
import { FadeAnimationLayout } from "../components/layouts/FadeAnimationLayout";
import useServer from "../hooks/useServer";
import Course from "../models/Course";
import { useSelector } from "react-redux";
import UpsertCourseModal from "../components/modals/UpsertCourseModal";
import CourseAction from "../models/CourseAction";

export default function Courses() {
    const [loading, setLoading] = React.useState<boolean>(true);
    const [courses, setCourses] = React.useState<Course[]>([]);
    const [addCourse, setAddCourse] = React.useState<boolean>(false);
    const server = useServer();
    const isAdmin = useSelector((state: any) => state.user.isAdmin);

    useEffect(() => {
        // Fetch the courses
        server.getCourses().then((courses: any) => {
            if (courses) {
                setCourses(courses);
                setLoading(false);
            }
        })
    }, [addCourse]);

    return (
        <React.Fragment>
            {
                loading ?
                    <Loader />
                    :
                    <div className="courses-page">
                        <div className="content-wrapper">
                            <FadeAnimationLayout>
                                <h1 id="title">Select a course to start practicing</h1>
                                <div className="cards-wrapper">
                                    {
                                        courses.map((course: Course) => {
                                            return (
                                                <Link
                                                    key={course.id}
                                                    to={`/courses/${course.id}/topics`}
                                                    state={{ courseTitle: course.name }}
                                                >
                                                    <CourseCard
                                                        id={course.id}
                                                        name={course.name}
                                                        description={course.description}
                                                        image={Logo}
                                                    />
                                                </Link>
                                            )
                                        })
                                    }
                                    {
                                        isAdmin &&
                                        <div onClick={() => setAddCourse(true)}>
                                            <CourseCard add={true} />
                                        </div>

                                    }
                                </div>
                            </FadeAnimationLayout>
                        </div>
                        {
                            addCourse &&
                            <UpsertCourseModal
                                action={CourseAction.ADD}
                                onClose={() => setAddCourse(false)}
                            />
                        }
                    </div>

            }
        </React.Fragment>
    )
}
