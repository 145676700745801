import { FadeAnimationLayout } from "../components/layouts/FadeAnimationLayout"
import { useSelector } from "react-redux";
import Button from "../components/buttons/Button";
import "../assets/styles/Authenticate.css";

export default function Authenticate() {
    const authUrl = useSelector((state: any) => state.school.authUrl);

    const onLogIn = () => {
        window.location.href = authUrl;
    }

    return (
        <FadeAnimationLayout>
            <div className="auth-page">
                <h1 id="title">Log in</h1>
                <p id="description">
                    Please log in to your account to continue.
                </p>
                <div id="auth-btn">
                    <Button label='Log in' onClick={onLogIn} />
                </div>
            </div>
        </FadeAnimationLayout>
    )
}