import { Link } from 'react-router-dom';
import '../assets/styles/Tab.css';

export default function Tab(props: any) {
    // Check if active
    const className = 'tab' + (props.active ? ' active' : ' inactive');
    return (
        <Link to={props.to}>
            <div className={className}>
                <p>{props.name}</p>
            </div>
        </Link>
    );
}