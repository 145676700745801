import JSZip from 'jszip';
import Image from './Image';
import { FailedImageArchiveParseResult, ImageArchiveParseResult, SuccessfulImageArchiveParseResult } from './ImageArchiveParseResult';

export default abstract class ImageArchiveParser {
    public static async parse(file: File): Promise<ImageArchiveParseResult> {
        // Initialize the library
        const zip: JSZip = new JSZip();

        try {
            // Load the file
            const zipFile = await zip.loadAsync(file);

            // Initialize the list of images
            const images: Image[] = [];

            // Iterate through the files in the archive
            for (const file in zipFile.files) {
                // Ignore __MACOSX folder
                if (file.match(/__MACOSX/)) continue;

                // If file is an image, add it to the list
                if (zipFile.files[file].name.match(/\.(jpg|jpeg|png|gif)$/i)) {
                    const data = await zipFile.files[file].async('base64');
                    // Get only the filename
                    const name = zipFile.files[file].name.split('/').pop()!;

                    // Add the image to the list
                    images.push({
                        name: name,
                        data,
                    })
                }
            }

            return new SuccessfulImageArchiveParseResult(images);
        }
        catch (error) {
            return new FailedImageArchiveParseResult("An error has occurred while parsing the image archive.");
        }

    }
}