import '../assets/styles/TabBar.css';

export default function TabBar(props: any) {
    return (
        <div className='tab-bar'>
            {
                props.children
            }
        </div>
    )
}