
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/Reducers';

const logging = (store: any) => (next: any) => (action: any) => {
    console.log('before', store.getState());
    console.log('action', action);
    next(action);
    console.log('after', store.getState());
}

const store = configureStore({
    reducer: rootReducer,
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logging),
});

export default store;
