import QuizCard from './cards/QuizCard';
import { FadeAnimationLayout } from './layouts/FadeAnimationLayout';
import { useOutletContext } from 'react-router-dom';
import React from 'react';
import Course from '../models/Course';
import QuizType from '../models/QuizType';
import QuizSummary from '../models/QuizSummary';
import useServer from '../hooks/useServer';
import { toast } from 'react-hot-toast';
import '../assets/styles/QuizzesTab.css';


export default function QuizzesTab(props: any) {
    const context: any = useOutletContext();
    const course: Course = context.course;
    const setCourse: Function = context.setCourse;
    const courseId: number = course.id;
    const quizzesSummary: QuizSummary[] = course?.quizzesSummary?.filter((quizSummary: QuizSummary) => quizSummary.type === props.quizType) || [];

    const server = useServer();

    const startQuiz = (quizId: string) => {
        window.location.href = `/classroom/${courseId}/${quizId}`;
    }

    const resetQuiz = (quizId: string) => {
        server.resetQuiz(courseId, quizId)
            .then((response: any) => {
                toast.success("Quiz reset successfully");
                setCourse({
                    ...course,
                    quizzesSummary: course.quizzesSummary!.map((quizSummary: QuizSummary) => {
                        if (quizSummary.id === quizId) {
                            delete quizSummary.user_progress;
                        }
                        return quizSummary;
                    }
                    )
                })
            })

    }

    const redoQuiz = (quizId: string) => {
        server.resetQuiz(courseId, quizId)
            .then((response: any) => {
                startQuiz(quizId);
            })
    }


    let quizTypeString: string = "";

    switch (props.quizType) {
        case QuizType.TOPIC:
            quizTypeString = "topics";
            break;
        case QuizType.PRACTICE:
            quizTypeString = "practice tests";
            break;
        case QuizType.EXAM:
            quizTypeString = "exams";
            break;
    }

    return (
        <FadeAnimationLayout>
            <div className='quizzes-tab'>
                {
                    quizzesSummary.length === 0 ?
                        <p id="banner">No {quizTypeString} for this course.</p> :
                        <React.Fragment>

                            <div id="banner">
                                <p>Complete all {quizzesSummary.length} {quizTypeString}.</p>
                            </div>
                            <div className="cards-wrapper">
                                {
                                    quizzesSummary.map((quizSummary: QuizSummary) => {
                                        return (
                                            <QuizCard
                                                key={quizSummary.id}
                                                courseId={courseId}
                                                quizSummary={quizSummary}
                                                onStart={() => startQuiz(quizSummary.id)}
                                                onContinue={() => startQuiz(quizSummary.id)}
                                                onReview={() => startQuiz(quizSummary.id)}
                                                onReset={() => resetQuiz(quizSummary.id)}
                                                onRedo={() => redoQuiz(quizSummary.id)}
                                            />
                                        )
                                    }
                                    )
                                }
                            </div>
                        </React.Fragment>

                }
            </div>
        </FadeAnimationLayout>
    )
}