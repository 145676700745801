import Image from "./Image";

export interface ImageArchiveParseResult {
    isSuccessful: boolean;
    value: Image[] | string;
}

export class SuccessfulImageArchiveParseResult implements ImageArchiveParseResult {
    public isSuccessful: boolean = true;
    public value: Image[];

    constructor(images: Image[]) {
        this.value = images
    }
}

export class FailedImageArchiveParseResult implements ImageArchiveParseResult {
    public isSuccessful: boolean = false;
    public value: string;

    constructor(error: string) {
        this.value = error;
    }
}

