import '../assets/styles/MenuBar.css';
import { Link } from 'react-router-dom';
import useServer from '../hooks/useServer';

export default function MenuBar() {
    const server = useServer();

    const supportPageUrl = "https://exam-review.com/support";

    const logout = () => {
        // Remove the user from the local storage
        localStorage.removeItem("isAdmin");

        // Logout
        server.logout()
            .then((response: any) => {
                // Redirect to login
                window.location.href = "/auth";
            })

    }

    return (
        <div className="menu-bar">
            <Link className="page" to="/courses" >
                <p>Courses</p>
            </Link>
            <a className="page" href={supportPageUrl} target="_blank" rel="noreferrer" >
                <p>Support</p>
            </a>
            <div className="page" onClick={logout} >
                <p>Log out</p>
            </div>
        </div>
    )
}