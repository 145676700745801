
const initialState: any = {
    courses: [],
    authUrl: '',
    enrollUrl: '',
}


export default function SchoolReducer(state = initialState, action: any) {
    switch (action.type) {
        case 'auth_url/set':
            return {
                ...state,
                authUrl: action.payload,
            };

        case 'enroll_url/set':
            return {
                ...state,
                enrollUrl: action.payload,
            };

        default:
            return state;
    }
}