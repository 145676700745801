import { FadeAnimationLayout } from "../components/layouts/FadeAnimationLayout"
import { useSelector } from "react-redux";
import Button from "../components/buttons/Button";
import "../assets/styles/Enroll.css";

export default function Enroll() {
    const enrollUrl = useSelector((state: any) => state.school.enrollUrl);

    const onEnroll = () => {
        window.location.href = enrollUrl;
    }

    return (
        <FadeAnimationLayout>
            <div className="enroll-page">
                <h1 id="title">Not enrolled!</h1>
                <p id="description">
                    You are currently not enrolled in any course! Please enroll in a course to start practicing.
                </p>
                <div id="enroll-btn">
                    <Button label="Enroll" onClick={onEnroll} />
                </div>
            </div>
        </FadeAnimationLayout>
    )
}