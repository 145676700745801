import '../../assets/styles/CourseCard.css';
import React from 'react';

export default function CourseCard(props: any) {
    // const className = `course-card ${props?.available ? 'available' : 'unavailable'}`

    const maximumCharacters = 12;
    const courseName = props.name;
    // const courseName = props.name;
    let longestWord = '';

    if (courseName) {
        if (courseName.split(' ').length === 1) {
            longestWord = courseName;
        }
        else {
            longestWord = courseName.split(' ').reduce((a: any, b: any) => a.length > b.length ? a : b, '');
        }
    }

    const className = props.add ? 'course-card add' : 'course-card';
    return (
        <div className={className}>
            {
                props.add ?
                    <p>+</p>
                    :
                    <React.Fragment>
                        <div id="title-wrapper">
                            <h1 id="title"
                                style={{
                                    fontSize: longestWord.length >= maximumCharacters ? 18 - longestWord.length / 3.5 : '18px',
                                }}
                            >{courseName}</h1>
                        </div>
                        {
                            props.image &&
                            <div id="image">
                                <img src={props.image} alt="card" />
                            </div>
                        }
                    </React.Fragment>
            }
        </div >
    )
}