import { motion } from 'framer-motion';

function FadeAnimationLayout(props: any) {
    return (
        <motion.div
            initial="initial"
            variants={pageVariants}
            exit="out"
            animate="in"
            transition={pageTransition}
            style={{
                position: "relative",
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            {
                props.children
            }
        </motion.div>
    );
};

const pageVariants = {
    initial: {
        opacity: 0,
    },
    in: {
        opacity: 1
    },
    out: {
        opacity: 0,
    }
};

const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.5
};

export { FadeAnimationLayout, pageVariants, pageTransition };